import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import { isBlackFridayOfferEnded } from '~components/black-friday/blackFridayUtils';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '~src/components/black-friday/constants';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const isBlackFridayOfferEnabled = IS_BLACK_FRIDAY_OFFER_ENABLED && !isBlackFridayOfferEnded();

const useStyles = makeStyles(theme => ({
  title: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 'normal',
    maxWidth: '450px',
    marginTop: 0,
    marginBottom: '18px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      fontSize: '18px',
      marginBottom: '10px'
    }
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '16px',
    columnGap: '16px'
  },
  ctaButton: {
    padding: '14px 30px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '130%',
    textAlign: 'center',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '14px'
    }
  },
  ctaBlueVariant: {
    color: '#FFFFFF',
    backgroundColor: '#1D91FF',
    '&:hover': {
      backgroundColor: '#1D91FF',
      textDecoration: 'none'
    }
  },
  ctaWhiteVariant: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      textDecoration: 'none'
    }
  },
  loginText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '130%',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 6,
    '& > a': {
      color: '#FFFFFF',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  }
}));

const NonFreeTrialBlocker = () => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();

  return (
    <>
      <p className={classes.title}>Become a premium member today to unlock the full video</p>

      <div className={classes.ctaContainer}>
        <Button
          component={Link}
          className={classNames(classes.ctaButton, classes.ctaBlueVariant)}
          to={
            isBlackFridayOfferEnabled
              ? getNavigationPageLink(BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH, isMarketingSite)
              : getNavigationPageLink('/subscribe', isMarketingSite)
          }
        >
          Go Premium
        </Button>
      </div>
      <div>
        <p className={classes.loginText}>
          Already a member?{' '}
          <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in</Link>
        </p>
      </div>
    </>
  );
};

export default NonFreeTrialBlocker;
