import React from 'react';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import { isBlackFridayOfferEnded } from '~components/black-friday/blackFridayUtils';
import NonFreeTrialBlocker from './NonFreeTrialBlocker';
import FreeTrialBlocker from './FreeTrialBlocker';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);
const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const isBlackFridayOfferEnabled = IS_BLACK_FRIDAY_OFFER_ENABLED && !isBlackFridayOfferEnded();

const PremiumContentBlocker = () => {
  if (isBlackFridayOfferEnabled || !IS_FREE_TRIAL_ENABLED) {
    return <NonFreeTrialBlocker />;
  }

  return <FreeTrialBlocker />;
};

export default PremiumContentBlocker;
